import "./App.css";
import PlanItem from "./planItem/PlanItem";
import { useState } from "react";

const PROGRAM = [
  {
    title: "Знакомство с языком",
    grammar: ["фонетика", "звуки", "буквы (Háčky, čárký)"],
    lexical:
      "Первые слова, которые звучат также как и" +
      " в русском языке (voda, vaza...)",
    result:
      "Начнёшь понимать как звучит чешский язык, его отличие в звучании по сравнению с русским и украинским",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Знакомство с тобой",
    grammar: [
      "Как формулировать вопросы",
      "Специальные вопросительные слова",
      "Как правильно отвечать на вопросы",
    ],
    lexical:
      "Основные фразы для знакомства, наречия состояния (чтобы ответить на вопрос как дела)",
    result:
      "Сможешь свободно и легко говорить о себе, знакомиться в компании, а на вопрос „Как дела?“, сможешь ответить более интересно, чем просто „нормально“",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Дневная рутина",
    grammar: [
      "Группы глаголов",
      "Спряжение глаголов",
      "Неправильные глаголы",
      "Возвратные глаголы",
      "Простые числительные для обозначения времени (в 5 утра, в 7 вечера и" +
        " т.д.)",
    ],
    lexical: [
      "Слова обозначающие время (утром, днём, в первой половине дня и т.д.)",
      "Ежедневные дела",
      "Дни, месяца, поры года",
    ],
    result:
      "Без проблем расскажешь о том, как прошёл твой день или чем ты занимаешься. Начнёшь ориентироваться в чешских месяцах и узнаешь что такое letos. ",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Слова вокруг нас",
    grammar: ["Группы существительных", "Падежи"],
    lexical: "Предметы, которые нас окружают (в комнате, в классе, дома, …)",
    result:
      "Значительно увеличишь свой словарный запас, поймёшь как формируются чешские слова, научишься их склонять",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Продукты и ресторан",
    grammar: "Винительный неодушевлённый падеж",
    lexical: ["Продукты", "Как заказать блюда", "Фразы в ресторане"],
    result:
      "Сможешь спросить в магазине, „есть ли у них сгущёнка“, чувствовать себя уверенно и свободно в ресторанах, понимать как вежливо отказаться или попросить",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Семья",
    grammar: "Винительный одушевлённый падеж",
    lexical: ["Члены семьи", "Родственники", "Глаголы mít rád, líbit se"],
    result:
      "Научишься рассказывать о своей семье, говорить о своих предпочтениях",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Хобби",
    grammar: "Закрепление глаголов",
    lexical: [
      "Хобби, увлечения",
      "Глаголы,  связь с глаголами ",
      "„Мне нравится делать…“",
    ],
    result:
      "Начнёшь свободно использовать глаголы и узнаешь о тонкостях вежливых формулировок и мягких просьб",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Одежда и поход в магазин",
    grammar: ["Закрепление винительного падежа", "Родительский падеж"],
    lexical: [
      "Одежда",
      "Виды магазинов, где что можно купить",
      "Цвета",
      "Разговоры в магазине одежды",
    ],
    result:
      "Будешь чувствовать себя удобно на шоппинге с друзьями или в разговоре с продавцом",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Поход к врачу",
    grammar: ["Модальные глаголы", "Примеры предписаний врачей"],
    lexical: ["Разговоры с врачом", "Части тела", "Запись к врачу"],
    result:
      "Сможешь сам записаться к врачу и свободно рассказать о своей проблеме. Будешь понимать особенности системы здравоохранения в Чехии и чувствовать себя у врача также как чехи",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Прошлое",
    grammar: [
      "Прошедшее время",
      "Прошедшее время с возвратными глаголами",
      "Написание неформальных писем",
    ],
    lexical: ["Моя биография", "Рассказ о себе"],
    result:
      "С лёгкостью будешь рассказывать о случившихся с тобой событиях. Перестанешь чувствовать дискомфорт в переписке с чехами.",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Движение и путишествия",
    grammar: ["Глаголы движения", "Предлоги направления"],
    lexical: [
      "Рассказываем о путешествиях (в прошедшем времени)",
      "Где были? Что делали? Куда ходили? Что понравилось?",
    ],
    result:
      "Освоишь новый словарный запас и сможешь интересно рассказать друзьям как ты провёл лето",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Поздравления",
    grammar: "Дательный падеж",
    lexical: [
      "Какие праздники празднуют чехи?",
      "Как поздравить с днём рождения на чешском?",
    ],
    result:
      "Узнаешь как, когда и в какой форме надо поздравлять чешский друзей/коллег с разными праздниками. Приобщишься к чешской культуре и узнаешь как сказать „поздравляю с днём рождения, желаю всего наилучшего“",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Квартира",
    grammar: "Предложный падеж",
    lexical: [
      "Как описать интерьер?",
      "Как искать мебель и как правильно „гуглить“?",
    ],
    result:
      "Будешь свободно ориентироваться в квартире, понимать разницу между 2kk и 2+1, и знать каждую вещь, которая находиться у тебя под глазами. Например, digestoř",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Учреждения",
    grammar: ["Вежливые формулировки предложений", "Формальные письма"],
    lexical: [
      "На почте",
      "В учреждениях",
      "Какие учреждения в Чехии есть",
      "Где что сделать",
    ],
    result:
      "Узнаешь по какому вопросу куда обращаться, как писать или разговаривать" +
      " в тех или иных учреждениях.",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Место",
    grammar: "Предложный падёж",
    lexical: [
      "Кто больше мест посетил?",
      "Какие бывают места, достопримечательности",
    ],
    result:
      "Сможешь поддерживать разговор на культурную и околокультурную темы, говорить об архитектуре, статуях и парках. Узнаешь крутые места",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Професии",
    grammar: "Творительный падеж",
    lexical: "Профессии (мужское и женское название)",
    result:
      "Свободно будешь чувствовать себя при разговоре о работе, на собеседовании и в жизни",
  },
  //------------------------------------------------------------------------------------
  {
    title: "Будущее",
    grammar: [
      "Будущее: не совершенный и совершенный вид глагола",
      "Приставки глаголов",
      "Союзы",
    ],
    lexical: [
      "Кем хоте стать",
      "Чем хочу заниматься, когда выучу чешский на б2",
    ],
    result: "Сможешь с лёгкостью рассказывать о своих планах, намерениях ",
  },
];

const LIMIT_VISIBLE = 3;

function App() {
  const [open, setOpen] = useState(false);
  return (
    <div className="body">
      {/*required to match tilda layout*/}
      <div style={{ maxWidth: 1160, margin: "0 auto" }}>
        {PROGRAM.slice(0, open ? PROGRAM.length : LIMIT_VISIBLE).map(
          ({ title, ...rest }, i) => (
            <PlanItem key={title} title={[i + 1, title].join(". ")} {...rest} />
          )
        )}
        <button className="button" onClick={() => setOpen(!open)}>
          {open ? "Свернуть программу" : "Открыть программу полностью"}
        </button>
      </div>
    </div>
  );
}

export default App;
