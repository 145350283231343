import React from "react";
import "./styles.css";

const PlanDescription = ({ title, description, className = "" }) => (
  <div className={`plan-description ${className}`}>
    <h3>{title}</h3>
    {Array.isArray(description) ? (
      <ul>
        {description.map((value) => (
          <li>{value}</li>
        ))}
      </ul>
    ) : (
      <p>{description}</p>
    )}
  </div>
);

export default PlanDescription;
