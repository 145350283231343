import React from "react";
import "./styles.css";
import PlanDescription from "./PlanDescription";

const PlanItem = ({ title, grammar, lexical, result }) => {
  return (
    <div className="plan-item">
      <h2>{title}</h2>
      <div className="content">
        <div className="description">
          <PlanDescription title="Грамматика" description={grammar} />
          <PlanDescription title="Лексика" description={lexical} />
        </div>
        <PlanDescription
          title="Результат"
          description={result}
          className={"result"}
        />
      </div>
    </div>
  );
};

export default PlanItem;
